<template>
  <b-container>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-card-title>
            Información de la categoría
          </b-card-title>
          <validation-observer ref="formValidation">
            <b-form @submit.prevent="validateForm()">
              <b-form-group label="Nombre de la categoría">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre de la categoría"
                  rules="required"
                >
                  <b-form-input
                    v-model="category.name"
                    placeholder="Categoría 1"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
              >
                Guardar
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BCardTitle,
  BContainer,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardTitle,
    BContainer,
  },
  data() {
    return {
      required,
      category: {
        id: '',
        name: '',
        active_status: '',
      },
    }
  },
  computed: {
    ...mapGetters('categories', ['currentCategory']),
  },
  mounted() {
    if (this.$route.params.id) {
      this.getCategory(this.$route.params.id)
        .then(() => {
          this.category = this.currentCategory
        })
    }
  },
  methods: {
    ...mapActions('categories', ['addCategory', 'editCategory', 'getCategory']),
    validateForm() {
      if (this.$route.params.id) {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            this.editCategory(this.category)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Categoría actualizada',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'Categories' })
              })
          }
        })
      } else {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            this.addCategory(this.category)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Categoría creada',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'Categories' })
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
